import React from 'react';
import { Grid } from '@material-ui/core';
import DashboardCard from '../components/DashboardCard';
import "./Dashboard.scss";
import LinksSection from '../sections/LinksSection';

import StatisticsSection from '../sections/StatisticsSection';
import NotificationsSection from '../sections/NotificationsSection';
import NewsSection from '../sections/NewsSection';
import CalendarSection from '../sections/CalendarSection';
import { withRemote } from '../utils/withRemote';
import TopJudgesSection from '../sections/TopJudgesSection';
import StatsBlockSection from '../sections/StatsBlockSection';

function Dashboard(props) {

	


  return (
		<Grid container spacing={3} className="dashboard-container">
			<div>
			<DashboardCard header="Links" className="links-card">
				<LinksSection me={props.me} setMe={props.setMe} />
			</DashboardCard>
			<DashboardCard header="Calendar" className="calendar-card">
				<CalendarSection events={props.me && props.me.events} />
			</DashboardCard>
			</div>

			<div>
				<DashboardCard className="top-judges-card" header="Your Stats">
					<p>This space will be used to display your personal case stats</p>
				</DashboardCard>
{/* 				<DashboardCard className="top-judges-card" header="Top 3 Judges">
					<TopJudgesSection />
				</DashboardCard>
				<DashboardCard className="stats-card" header="Your Stats">
					<StatsBlockSection />
				</DashboardCard>
 */}			
			<DashboardCard header="News" className="news-card">
				<NewsSection />
			</DashboardCard>
			</div>
		</Grid>
  );
}

export default withRemote(Dashboard);