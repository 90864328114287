import React from 'react';
import "./DropdownItem.scss";

export default function DropDownItem(props) {

	const { children, className, onClick, activeItem } = props;
	
	return (
		<div onClick={onClick} className={`dropdown-item${className ? " " + className : ""}${activeItem ? " active-item" : ""}`}>
			{children}
		</div>
	);
}