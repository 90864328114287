import React from 'react';
import "./Table.scss";

export default function Table(props) {

	const { className, children, tableHeader } = props;
	
	return (
		<>
			{tableHeader && <p className="portal-table-header">{tableHeader}</p>}
			<table className={`portal-table${className ? " " + className : ""}`}>
				{children}
			</table>
		</>
	);
}