import React from 'react';
import RemoteImpl from './RemoteImpl';

const remote = new RemoteImpl();

export function withRemote(Child) {
	return class extends React.Component {

		render() {
			return (
				<Child {...this.props} remote={remote} />
			);
		}
	}
}
