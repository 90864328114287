import React from 'react';
import "./VerticalDivider.scss";

export default function VerticalDivider(props) {

	const { className, height, margin } = props;
	
	return (
		<div className={`vertical-divider${className ? " " + className : ""}`} style={{height: height+"px", marginLeft: margin+"px", marginRight: margin+"px"}} />
	);
}