import React from 'react';
import CopyIcon from '../icons/CopyIcon';
import FavouriteIcon from '../icons/FavouriteIcon';
import LaunchIcon from '../icons/LaunchIcon';
import { withRemote } from '../utils/withRemote';
import "./LinkListIcons.scss";

function LinkListIcons(props) {	
	const {link,id} = props.link;
	const {favourite,setFavourite} = props;

	const clickFavourite = () => {
		if (favourite) {
			props.remote.delete("/favourite/"+id).then(setFavourite);
		} else {
			props.remote.post("/favourite/"+id).then(setFavourite);
		}
	}

	return (
		<div className="link-list-icons">
			<FavouriteIcon link={link} active={favourite} onClick={() => clickFavourite()} />
			<a style={{marginRight:"20px"}} href={link} target="_blank"><LaunchIcon link={link} /></a>
			<CopyIcon link={link}/>
		</div>
	);
}

export default withRemote(LinkListIcons);