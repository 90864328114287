import React, { useEffect, useState }  from 'react';
import Admin from '../Admin';
import SideNav from '../components/SideNav';
import Dashboard from '../pages/Dashboard';
import PageContentHeader from './PageContentHeader';
import "./PageLayout.scss"
import { Switch, Route } from "react-router-dom";

import {ReactComponent as HomeIcon} from "../icons/home.svg";
import {ReactComponent as AdminIcon} from "../icons/admin.svg";
import {ReactComponent as TrainingIcon} from "../icons/training.svg";
import {ReactComponent as NewsIcon} from "../icons/news.svg";
import {ReactComponent as SettingsIcon} from "../icons/settings.svg";
import Settings from '../pages/Settings';
import Training from '../pages/Training';
import { withRemote } from '../utils/withRemote';
import useInterval from '../utils/useInterval';
import { Grid } from '@material-ui/core';
import Card from '../components/Card';
import Empanelment from '../pages/empanelment/Empanelment';

const adminPages = [
	{ path: "/", label: "Home", exact: true, icon:  <HomeIcon />},
	{ path: "/ui/admin", label: "Admin", icon:  <AdminIcon />},
	{ path: "/ui/empanelment", label: "Empanelment", icon:  <AdminIcon />},
	{ path: "/ui/training", label: "Training", icon:  <TrainingIcon />},
];

// const nonAdminPages = [
// 	{ path: "/", label: "Home", exact: true, icon:  <HomeIcon />},
// 	{ path: "/ui/training", label: "Training", icon:  <TrainingIcon />},
// ];

//	{ path: "/ui/news", label: "News", icon:  <NewsIcon />},
//	{ path: "/ui/settings", label: "Settings", icon:  <SettingsIcon />},

function PageLayout(props) {
	const { logout } = props;
	const [me, setMe] = useState({favouriteLinks:[]});
	const [notifications, setNotifications] = useState([]);
	const [training, setTraining] = useState([]);

	const [pages, setPages] = useState([]);

	useEffect(() => {
		props.remote.get("/me")
			.then(setMe)
			.catch(error => console.error(error));
		props.remote.get("/notifications")
			.then(setNotifications);
		props.remote.get("/training")
			.then(setTraining);

	},[]);

	useEffect(() => {
		if (me.hasAdminAccess) {
			setPages(adminPages);
		} else {
			const nonAdminPages = [
				{ path: "/", label: "Home", exact: true, icon:  <HomeIcon />},
				{ path: "/ui/training", label: "Training", icon:  <TrainingIcon />},
			];
			if (me.roles && me.roles.includes("Empanelment")) {
				nonAdminPages.push({ path: "/ui/empanelment", label: "Empanelment", icon:  <AdminIcon />});
			}
			setPages(nonAdminPages);
		}
	}, [me])

	useInterval(() => {
		props.remote.get("/notifications").then(setNotifications);
	}, 300000)

	const refreshNotifications = () => {
		props.remote.get("/notifications")
			.then(setNotifications);
	}

	if (!me.displayName) {
		return null;
	}

	if (me && me.displayName && !me.hasWebsiteAccess) {
		return (
			<Grid container justify="center" alignItems="center" className="login-container">
			<Grid item xs={12} sm={9} lg={6}>
				<Card>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<h1 className="my-0 colour-secondary">Admin access not permitted</h1>
							<p>
								Admin access to the <span className="colour-accent-2"> Judges Portal</span> is restricted.
								Please contact Alison Holt if you believe you should have access. 
							</p>
						</Grid>
					</Grid>
				</Card>
			</Grid>
			</Grid>
		)
	}

	return (
		<div className="page-layout-master-wrapper">
			<SideNav pages={pages} />
			<div className="page-content-wrapper">
				{/* Page header(search, page header etc) */}
				<PageContentHeader pages={pages} me={me} logout={logout} notifications={notifications}  setNotifications={setNotifications}/>
				{/* Pages go in here */}
				<div className="page-content-inner">
					<Switch>
						<Route exact path="/ui/">
							<Dashboard me={me} setMe={setMe} notifications={notifications} />
						</Route>
						{me && me.hasAdminAccess && <Route path="/ui/admin" render={(props) => <Admin {...props} refresh={refreshNotifications} />}/>}
						{me && (me.hasAdminAccess || (me.roles && me.roles.includes("Empanelment"))) && <Route path="/ui/empanelment">
							<Empanelment/>
						</Route>
						}
						<Route path="/ui/settings">
							<Settings />
						</Route>
						<Route path="/ui/training">
							<Training training={training}/>
						</Route>

					</Switch>
				</div>
			</div>
		</div>
  );
}


export default withRemote(PageLayout);