import React from 'react';
import "./Card.scss";

export default function Card(props) {

	const { children, className } = props;
	
	return (
		<div className={`portal-card${className ? " " + className : ""}`}>
			{children}
		</div>
	);
}