import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, Snackbar, SnackbarContent, TextField} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withRemote } from '../../utils/withRemote';
import Icon from '../../icons/Icon';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultSnackbar, ErrorSnackbar, MyRedSnackbar, SuccessSnackbar } from '../../components/SnackBars';

import ImageCropper from '../../utils/ImageCropper';

function TrainingAdmin(props) {

	const [success, setSuccess] = React.useState(false);
	const [error, setError] = React.useState(false);

	const [training, setTraining] = React.useState({
		title: "",
		abstract: "",

	})

	const createTraining = (e) => {

		const {...trainingToSend} = training;
		console.log("Sending ",trainingToSend);
		props.remote.post("/training",trainingToSend).then(() => {
			setSuccess(true);
			setTraining({title:"", abstract: ""});
		}).catch((e) => {
			setError(true);
		});
		
		
		

	}


	const handleTrainingChange = (event, part) => {
		const val = event.target.value;
		const {...dialogTraining} = training;
		dialogTraining[part] = val;
		setTraining(dialogTraining) 
	}

	const setAndDownsizeImage = (img) => {
//		img = img.substring('data:image/png;base64,'.length);
		//console.log("Setting and downsizing ", img);
		//const resize = 'data:image/png;base64,'+resizebase64(img, 600, 200); 
		resizedataURL(img, 600, 200);

		//handleTrainingChange({"target":{"value":resize}}, "image");
	}

	const resizedataURL = (datas, wantedWidth, wantedHeight) => {
        // We create an image to receive the Data URI
        var img = document.createElement('img');

        // When the event "onload" is triggered we can resize the image.
        img.onload = function() {        
                // We create a canvas and get its context.
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext('2d');

                // We set the dimensions at the wanted size.
                canvas.width = wantedWidth;
                canvas.height = wantedHeight;

                // We resize the image with the canvas method drawImage();
                ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);

                var dataURI = canvas.toDataURL();
				//console.log("Data uri of resized component is ", dataURI);
                /////////////////////////////////////////
                // Use and treat your Data URI here !! //
                /////////////////////////////////////////
				handleTrainingChange({"target":{"value":dataURI}}, "image");
            };

        // We put the Data URI in the image's src attribute
        img.src = datas;
    }

	const handleClose = (event, reason) => {
		console.log("handling close ", event, reason);
		if (reason === 'clickaway') {
		  return;
		}
		console.log("setting success  ", success);
		setSuccess(false);
		setError(false);
		console.log("setting success  ", success);
	};

	
	return (
		<>

			<DefaultSnackbar
				type={'success'}
				open={success}
				onClose={handleClose}
				message="Training event created"
			/>
			<DefaultSnackbar
				type={'error'}
				open={error}
				onClose={handleClose}
				message="There was a problem creating the training event. Please contact support"
			/>
				<DialogTitle id="form-dialog-title">Create a new training event</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Training events appear on the Training screen.
					</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Title"
						type="text"
						fullWidth
						value={training.title}
						inputProps={{ maxLength: 50 }}
						onChange={(e) => handleTrainingChange(e, "title")}
					/>
					<TextField
						margin="dense"
						id="title"
						label="Abstract"
						type="text"
						multiline="true"
						fullWidth
						rowsMax={3}
						value={training.abstract}
						inputProps={{ maxLength: 660 }}
						onChange={(e) => handleTrainingChange(e, "abstract")}
					/>
					<p className="MuiFormLabel-root">Image</p>
					{training.image && <>
						<img src={training.image} alt="Cropped" style={{width:"600px"}} />
						<Button onClick={() => handleTrainingChange({"target":{"value":null}}, "image")} color="secondary" style={{float:"right",backgroundColor:"#DBB564"}}>Remove image</Button>
					</>}
					{!training.image && <ImageCropper setCroppedImage={(img)=>setAndDownsizeImage(img)}/>}
					<span className="MuiFormControl-root"/>
					<TextField margin="dense" id="title" label="More information/Registration link (if applicable)" type="text"
						multiline="true" fullWidth value={training.joinLink} onChange={(e) => handleTrainingChange(e, "joinLink")}
						InputProps={{
							startAdornment: <InputAdornment position="start">https://</InputAdornment>,
					  	}}
					/>
					<TextField margin="dense" id="title" label="Zoom link (if applicable)" type="text" multiline="true" fullWidth
						InputProps={{
							startAdornment: <InputAdornment position="start">https://</InputAdornment>,
					  	}}
						value={training.zoomLink} onChange={(e) => handleTrainingChange(e, "zoomLink")}
					/>
					<TextField margin="dense" fullWidth id="datetime-local" label="Training date (if applicable)" type="datetime-local" 
					 InputLabelProps={{
						shrink: true,
						}}
						onChange={(e) => handleTrainingChange(e, "eventTime")}
					/>
					<TextField margin="dense" fullWidth id="datetime-local" label="Expiry date (date to archive this event from the portal)" type="date" 
					InputLabelProps={{
						shrink: true,
						}}
						onChange={(e) => handleTrainingChange(e, "expires")}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={createTraining} disabled={training.title.trim() === '' || training.abstract.trim() === ''} color="primary">
						Create
					</Button>
				</DialogActions>
		</>
	)

}

export default withRemote(TrainingAdmin);
