import React, { useEffect, useState } from 'react';
import LinkListIcons from '../components/LinkListIcons';
import Table from '../components/Table';
import { withRemote } from '../utils/withRemote';

function LinksSection(props) {

	const [links, setLinks] = useState([]);
	const {me, setMe} = props;
//	const {filter, setFilter} = useState('favourites');

	useEffect(() => {
		props.remote.get("/links")
			.then(setLinks)
			.catch(error => console.error(error));
	},[props.remote]);

	const setFavourites = (data) => {
		setMe(data);
	}

	return (
		<div className="links-section">
			{/*<p>{filter === 'favourites' ? "Show all" : "Show favourites only"}</p>*/}
			{links.map(linkSection => <Table tableHeader={linkSection.linkHeader} key={linkSection.id}>
				<tbody >
					{linkSection.links.map(link => <tr key={link.id}>
						<td>{link.description}</td>
						<td className="d-flex justify-content-end"><LinkListIcons setFavourite={setFavourites} favourite={me && me.favouriteLinks && me.favouriteLinks.includes(link.id)} link={link} /></td>
					</tr>)}
				</tbody>
			</Table>)

			}
		</div>
	);
}

export default withRemote(LinksSection);