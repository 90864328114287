import { formatDistance, parseJSON } from 'date-fns';
import React from 'react';
import "./NotificationItem.scss";
	
function NotificationItem(props) {
	const { text, read, date, onClick } = props;

	const formatDate = formatDistance(parseJSON(date), new Date(), { addSuffix: true });


	return (
		<div className={`notification-item-wrapper${read ? "" : " unread-notification"}`} onClick={onClick}>
			<div className="notification-text">{text}</div>
			<div className="notification-date">{formatDate}</div>
		</div>
	);
}

export default NotificationItem;