/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import { Tooltip } from '@material-ui/core';
import React from 'react';
import "./PortalToolTip.scss";


export default function PortalToolTip(props) {
	const { children, title } = props;

  return (
		<Tooltip title={title} placement="top" arrow className="portal-tooltip" enterDelay={400} leaveDelay={50}>
			{children}
		</Tooltip>
  );
}