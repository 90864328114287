import React from 'react';

export default function DropDownContent(props) {

	const { children, className } = props;
	
	return (
		<div className={`dropdown-content${className ? " " + className : ""}`}>
			{children}
		</div>
	);
}