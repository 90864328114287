import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Paper, Select as MuiSelect, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withRemote } from '../../utils/withRemote';
import Icon from '../../icons/Icon';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultSnackbar, ErrorSnackbar, MyRedSnackbar, SuccessSnackbar } from '../../components/SnackBars';
import Button from '../../components/Button';
import Select from 'react-select'

function PermissionsAdmin(props) {

	const [success, setSuccess] = React.useState(false);
	const [error, setError] = React.useState(false);
	const [modal, setModal] = React.useState(false);
	const [perms, setPerms] = React.useState([]);
	const [judge, setJudge] = React.useState({
		email:"",
		websiteAccess: true,
		appAccess: true,
		adminAccess: false,
		judicialType: 1,
		roles: [] 
	})
	const roles = [
		{ value: 'Empanelment', label: 'Empanelment' }
	  ]

	useEffect(() => {
		props.remote.get("/admin/permissions").then(setPerms);
	}, [])

	const handleClose = (event, reason) => {
		console.log("handling close ", event, reason);
		if (reason === 'clickaway') {
		  return;
		}
		console.log("setting success  ", success);
		setSuccess(false);
		setError(false);
		console.log("setting success  ", success);
	};

	const useStyles = makeStyles({
		table: {
			minWidth: 650,
		},
		fancyButton: {
			background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
			border: 0,
			borderRadius: 3,
			boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
			color: 'white',
			height: 48,
			padding: '0 30px',
		},
		box: {
			padding: '10px 0'
		}
	});

	const handleChange = (row, which) => {
		console.log(row, which);
		props.remote.put(`/admin/permissions/${row.email}/${which}`).then(setPerms).catch((e)=>{
			console.log(e);
			e.json().then(json => setError(json.error));
		});
	}
	
	const handleTypeChange = (row, which) => {
		console.log(row, which.target.value);
		props.remote.put(`/admin/judicial-type/${row.email}/${which.target.value}`).then(setPerms).catch((e)=>{
			console.log(e);
			e.json().then(json => setError(json.error));
		});
	}

	const handleModalOpen = () => {
		setModal(true);
	}

	const handleModalClose = () => {
		setModal(false);
	}

	const handleNewJudge = (e, field) => {
		console.log("Handling new judge ", field, e.target.value)
		const createJudge = judge;
		createJudge[field] = e.target.value;
		setJudge({...createJudge}); 
	}

	const handleNewJudgeBoolean = (e, field) => {
		console.log("Handling new judge ", field, e.target.value)
		const createJudge = judge;
		createJudge[field] = !createJudge[field];
		setJudge({...createJudge}); 
	}

	const createNewJudge = () => {
		if (!judge || !judge.email) {
			return;
		}
		props.remote.post(`/admin/permissions/${judge.judicialType}`, judge).then(setPerms).catch((e)=>{
			console.log(e);
			e.json().then(json => setError(json.error));
		});
		setModal(false);
		setJudge({email:"",
		websiteAccess: true,
		appAccess: true,
		adminAccess: false,
		judicialType: 1 })
	}

	const handleRoleChange = (event, action, row) => {
		console.log("Handling a role change with ",event, action, row);
		if (action.action === 'remove-value') {
			console.log("Removing so posting ",action.removedValue	 )
			props.remote.post(`/admin/roles/${row.email}/remove`, action.removedValue).then(()=>{}).catch((e)=>{
				console.log(e);
				e.json().then(json => setError(json.error));
			});
		} else if (action.action === 'select-option') {
			console.log("Adding so posting ",action.option )
	 		props.remote.post(`/admin/roles/${row.email}/add`, action.option).then(()=>{}).catch((e)=>{
			console.log(e);
			e.json().then(json => setError(json.error));
			});
		}
	}

	const classes = useStyles();

		return(
		<>

			<DefaultSnackbar
				type={'success'}
				open={success}
				onClose={handleClose}
				message="Training event created"
			/>
			<DefaultSnackbar
				type={'error'}
				open={error}
				onClose={handleClose}
				message={error}
			/>
					
			<Button onClick={handleModalOpen}>
       			Create user
			</Button>
			<Dialog open={modal} onClose={handleModalClose} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Create user</DialogTitle>
				<DialogContent>
				<DialogContentText>
					Set access rights for a new Judge
				</DialogContentText>
				<TextField
						autoFocus
						margin="dense"
						id="email"
						label="Email"
						type="text"
						fullWidth
						value={judge.email}
						inputProps={{ maxLength: 90 }}
						onChange={(e) => handleNewJudge(e, 'email')}
				/>
				<Box className={classes.box} display="flex" flexDirection={"row"} justifyContent={ 'space-between'}>Judicial type: 
					<MuiSelect labelId="jucicial-type" value={judge.judicialType} onChange={(e) => handleNewJudge(e, 'judicialType')}>
						<MenuItem value="1" aria-label="Civil">Civil</MenuItem>
						<MenuItem value="2" aria-label="Criminal">Criminal</MenuItem>
					</MuiSelect>
				</Box>
				<Box className={classes.box} display="flex" flexDirection={"row"} justifyContent={ 'space-between'}>
					Website access: <Switch checked={judge.websiteAccess} onChange={(e) => handleNewJudgeBoolean(e, 'websiteAccess')} color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} />
				</Box>
				<Box className={classes.box} display="flex" flexDirection={"row"} justifyContent={ 'space-between'}>
					App access: <Switch checked={judge.appAccess} onChange={(e) => handleNewJudgeBoolean(e, 'appAccess')} color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} />
				</Box>
				<Box className={classes.box} display="flex" flexDirection={"row"} justifyContent={ 'space-between'}>
					Admin access: <Switch checked={judge.adminAccess} onChange={(e) => handleNewJudgeBoolean(e, 'adminAccess')} color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} />
				</Box>
				</DialogContent>
				<DialogActions>
					<Button disabled={judge && judge.email.trim() === ''} color="primary" onClick={createNewJudge}>
						Create
					</Button>
				</DialogActions>
			</Dialog>
			<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
				<TableRow>
					<TableCell>User email</TableCell>
					<TableCell align="right">Judicial Type</TableCell>
					<TableCell align="right">Website Access</TableCell>
					<TableCell align="right">App Access</TableCell>
					<TableCell align="right">Admin Access</TableCell>
					<TableCell align="left">Roles</TableCell>
				</TableRow>
				</TableHead>
				<TableBody>
				{perms.map((row) => (
					<TableRow key={row.email}>
					<TableCell component="th" scope="row">
						{row.email}
					</TableCell>
					<TableCell align="right">
						<MuiSelect labelId="jucicial-type" value={row.judicialType} onChange={(e) => handleTypeChange(row, e)}>
							<MenuItem value="1" aria-label="Civil">Civil</MenuItem>
      						<MenuItem value="2" aria-label="Criminal">Criminal</MenuItem>
      						<MenuItem value="3" aria-label="Judge's Associate">Judge's Associate</MenuItem>
      						<MenuItem value="4" aria-label="Back office">Back office</MenuItem>
					    </MuiSelect>
					</TableCell>
					<TableCell align="right"><Switch checked={row.hasWebsiteAccess} onChange={(e) => handleChange(row, 'website')} color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} /></TableCell>
					<TableCell align="right"><Switch checked={row.hasAppAccess} onChange={(e) => handleChange(row, 'app')} color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} /></TableCell>
					<TableCell align="right"><Switch checked={row.hasAdminAccess} onChange={(e) => handleChange(row, 'admin')} color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} /></TableCell>
					<TableCell>
						<Select 
							isMulti 
							isClearable={false} 
							options={roles} 
							onChange={(e,a) => handleRoleChange(e, a, row)} 
							defaultValue={row.roles && row.roles.map(r => roles.find(v => v.value === r))}
							/>
					</TableCell>
					</TableRow>
				))}
				</TableBody>
			</Table>
			</TableContainer>
		</>
	)

}

export default withRemote(PermissionsAdmin);
