import React from 'react';
import PortalToolTip from '../components/PortalToolTip';
import Icon from './Icon';

export default function ToolTipIcon(props) {

	const { icon, title, active, classPrefix,onClick } = props;
	
	return (
		<PortalToolTip title={title}>
			<div onClick={onClick}>
				<Icon
					icon={icon}
					size="20"
					className={`${classPrefix}-icon${active ? " icon-active" : ""}`}
				/>
			</div>
		</PortalToolTip>
	);
}