import { Box, IconButton, Slide, Snackbar, SnackbarContent, withStyles } from "@material-ui/core";
import Icon from "../icons/Icon";

export const WeirdSnackbar = withStyles({
	root: {
	  background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
	  borderRadius: 3,
	  border: 0,
	  color: 'white',
	  height: 48,
	  padding: '0 30px',
	  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
	},
	label: {
	  textTransform: 'capitalize',
	},
})(Snackbar);

export const ErrorSnackbar = withStyles({
	root: {
	  background: 'red'
	},
})(Snackbar);

export const SuccessSnackbar = withStyles({
	root: {
	  background: 'green'
	},
})(Snackbar);


export const DefaultSnackbar = (props) => {

	const SlideTransition = (props) => {
		return <Slide {...props} direction="left" />;
	}

	const {type, ...rest} = props;

	return (
		<Snackbar
	        TransitionComponent={SlideTransition}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			autoHideDuration={6000}
			{...rest}
		>
			<SnackbarContent 
				style={{
					backgroundColor: type === 'success' ? '#4caf50' : '#f44336',
					width: '100%'
				}}
				message={<>
				<Box display="flex" flexDirection={"row"} justifyContent={ 'space-between'}>
					<div>{props.message}</div>
					<IconButton size="small" aria-label="close" color="inherit" onClick={props.onClose}>
						<Icon icon={'x'} size="20" className={`x`} />
					</IconButton>
				</Box>
				</>}/>
		</Snackbar>
	)
	  
}

/*   
const MySnackbar = (props) => {
	const { classes } = this.props;
	
	return (
		<Snackbar
		  ContentProps={{
			classes: {
			  root: classes.root
			}
		  }}
		  message={props.message}
		/>
	  );
}

const errorStyles = {
	root: {
	  background: 'red'
	}
};
  
export const MyRedSnackbar = withStyles(errorStyles)(MySnackbar); */