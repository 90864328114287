import React, { useState } from 'react';
import "./Dropdown.scss";

export default function DropDown(props) {
	const [open, setOpen] = useState(false);
  
	const handleToggle = () => {
	  setOpen((prevOpen) => !prevOpen);
	};

	const { children, className, right } = props;
	
	return (
		<div className={`dropdown-container${open ? " dropdown-open" : ""}${className ? " " + className : ""}${right ? " right-aligned" : ""}`} onClick={handleToggle}>
			{children}
		</div>
	);
}