import React from 'react';
import '../../../Admin.scss'
import CourtLocations from './CourtLocations';
import SittingJudgeAdmin from './JudgesAdmin'
//import resizebase64 from 'resize-base64';

import { Route, Switch, Redirect } from "react-router-dom";
import { RoutedTabs, NavTab } from "react-router-tabs";

// with default styles:
import "../../../styles/react-router-tabs.scss";
import SupremeCourtSchedule from './SupremeCourtSchedule';

const EmpanelmentAdmin = (props) => {

	const { match } = props;

	return (
		<div style={{paddingTop:"15px"}}>
			<NavTab to={`${match.path}/judges`}>Judges</NavTab>
			<NavTab to={`${match.path}/courts`}>Courts</NavTab>
			<NavTab to={`${match.path}/supreme-court-schedule`}>Supreme Court Schedule</NavTab>

			<Switch>
				<Route
					exact
					path={`${match.path}`}
					render={() => <Redirect replace to={`${match.path}/judges`} />}
				/>
				<Route path={`${match.path}/judges`} component={SittingJudgeAdmin} />
				<Route path={`${match.path}/courts`} component={CourtLocations} />
				<Route path={`${match.path}/supreme-court-schedule`} component={SupremeCourtSchedule} />
			</Switch>
		</div>
	);
};


export default EmpanelmentAdmin;
