import { Box, Grid, InputAdornment, Tab, Tabs, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withRemote } from './utils/withRemote';
import React from 'react';
import LinkManagement from './pages/admin/LinkManagement';
import './Admin.scss'
import Notification from './pages/admin/Notification';
import PermissionsAdmin from './pages/admin/PermissionsAdmin';
import TrainingAdmin from './pages/admin/TrainingAdmin';
//import resizebase64 from 'resize-base64';

import { Route, Switch, Redirect } from "react-router-dom";
import { RoutedTabs, NavTab } from "react-router-tabs";
 
// with default styles:
import "./styles/react-router-tabs.scss";
import EmpanelmentAdmin from './pages/admin/empanelment/EmpanelmentAdmin';
 
const Admin = (props) => { 

	console.log("Admin has props" , props);

	const {match} = props;
	
	return (
    <div>
      <NavTab to={`${match.path}/notifications`}>Notifications</NavTab>
      <NavTab to={`${match.path}/training`}>Training</NavTab>
      <NavTab to={`${match.path}/links`}>Links</NavTab>
      <NavTab to={`${match.path}/users`}>Users</NavTab>
      <NavTab to={`${match.path}/empanelment`}>Empanelment Admin</NavTab>
 
      <Switch>
        <Route
          exact
          path={`${match.path}`}
          render={() => <Redirect replace to={`${match.path}/notifications`} />}
        />
        <Route path={`${match.path}/notifications`} component={Notification} />
        <Route path={`${match.path}/training`} component={TrainingAdmin} />
        <Route path={`${match.path}/links`} component={LinkManagement} />
        <Route path={`${match.path}/users`} component={PermissionsAdmin} />
        <Route path={`${match.path}/empanelment`} component={EmpanelmentAdmin} />
      </Switch>
    </div>
  );
};


/**
 * Temp page for ws fed consent
 * @returns 
 */
function OldAdmin(props) {

	const { refresh } = props;

	const [open, setOpen] = React.useState(false);
	const [trainingOpen, setTrainingOpen] = React.useState(false);
	const [training, setTraining] = React.useState({
		title: "",
		abstract: "",

	})

	const [showLinks, setShowLinks] = React.useState(false);

	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleTrainingOpen = () => {
		setTrainingOpen(true);
	};

	const handleTrainingClose = () => {
		setTrainingOpen(false);
	};

	const handleLinkOpen = () => {
		setShowLinks(true);
	};





	return (
		<>
			<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
				<Tab label="Create Notification" {...a11yProps(0)} />
				<Tab label="Create Training" {...a11yProps(1)} />
				<Tab label="Link Management" {...a11yProps(2)} />
				<Tab label="Permissions" {...a11yProps(3)} />
			</Tabs>

			<TabPanel value={value} index={0}>
				<Notification />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<TrainingAdmin />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<LinkManagement />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<PermissionsAdmin />
			</TabPanel>
		</>
	);
}

export default withRemote(Admin);


function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}