import React from 'react';
import NavLinks from './NavLinks';
import "./SideNav.scss";
import splash from './splash3.png'
function SideNav(props) {
	const { pages } = props;
	return (
		<div className="sidenav-wrapper">
			<img src={splash}  style={{position:"absolute", top:"0px",left:"0px", "zIndex":"-1"}}/>
			<NavLinks pages={pages} />
		</div>
	);
}

export default SideNav;