import React, { useState } from 'react';
import NotificationItem from './NotificationItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from './Button';
import "./NotificationList.scss";
import { format, formatDistance, formatRelative, parseJSON } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime, format as tzFormat } from 'date-fns-tz';
import { withRemote } from '../utils/withRemote';
	
function NotificationList(props) {
	const { notifications, setNotifications } = props;
	
	const [dialogNotification, setDialogNotification] = useState(null);

	const open = (notification) => {
		setDialogNotification(notification);
		if (notification.read) {

		} else {
			props.remote.put("/notifications/"+notification.id).then(setNotifications);
		}
		
	}

	const formatDate = (date) => formatRelative(parseJSON(date), new Date() );

	return ( 
		<>
		<div className="notification-list-wrapper">
			<div className="notification-list-header">
				<h4>Notifications</h4>
			</div>
			<div className="notification-items-wrapper">
				{notifications && notifications.length >= 0 && notifications.map((notification, idx) => <NotificationItem key={idx} text={notification.title} read={notification.read} date={notification.notificationDate} onClick={() => open(notification)} />)}
			</div>
		</div>
		{dialogNotification && <Dialog open={dialogNotification} onClose={() => setDialogNotification(null)} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title"> 
					{dialogNotification.title}
					<div class="notification-date">{formatDate(dialogNotification.notificationDate)}</div>
				
				</DialogTitle>
				<DialogContent>
				{dialogNotification.notification}
				</DialogContent>
				<DialogActions>
				</DialogActions> 
			</Dialog>}
		</>
	);
}

export default withRemote(NotificationList);