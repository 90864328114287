import { Grid } from '@material-ui/core';
import React from 'react';
import InputField from '../components/InputField';

function Settings() {
  return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<h5 className="my-0">Profile Info</h5>
			</Grid>
			<Grid item xs={12} lg={5}>
				<InputField label="First Name" placeholder="First Name" name="first-name" />
			</Grid>
			<Grid item xs={12} lg={5}>
				<InputField label="Last Name" placeholder="Last Name" name="last-name" />
			</Grid>
			<Grid item xs={12} lg={5}>
				<InputField label="Email Address" placeholder="Email Address" name="email-address" />
			</Grid>
			<Grid item xs={12} lg={5}>
				<InputField label="Phone Number" placeholder="Phone Number" name="phone-number" />
			</Grid>
		</Grid>
  );
}

export default Settings;