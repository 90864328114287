import { Grid } from '@material-ui/core';
import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
//import InputField from '../components/InputField';
import "./LoginPage.scss";

function LoginPage() {

	const redirect = process.env.REACT_APP_REDIRECT;

  return (
		<Grid container justify="center" alignItems="center" className="login-container">
			<Grid item xs={12} sm={9} lg={6}>
				<Card>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<h1 className="my-0 colour-secondary">PNG Judges Portal</h1>
							<p>
								Please sign in using your <span className="colour-accent-2"> Office 365</span> credentials.
								The button below will securely prompt you for your credentials. 
							</p>
						</Grid>
						<Grid item xs={12}>
				<Button href={`https://login.microsoftonline.com/pngjudiciary.gov.pg/oauth2/v2.0/authorize?client_id=b3d2c65c-c2d6-4572-afb4-fe87dabe4870&response_type=code&response_mode=query&redirect_uri=${process.env.REACT_APP_REDIRECT}&scope=https://graph.microsoft.com/Calendars.Read https://graph.microsoft.com/email https://graph.microsoft.com/profile https://graph.microsoft.com/User.Read`}>
					Login
				</Button>
			</Grid>
			{/*<Grid item xs={12}>
				<p>Login (common)</p>
				<Button href={`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=b3d2c65c-c2d6-4572-afb4-fe87dabe4870&response_type=code&response_mode=query&redirect_uri=${process.env.REACT_APP_REDIRECT}&scope=https://graph.microsoft.com/Calendars.Read https://graph.microsoft.com/email https://graph.microsoft.com/profile https://graph.microsoft.com/User.Read`}>
					Common Login
				</Button>
  </Grid>*/}
			{/*}
						<Grid item xs="12">
							<InputField error={false} helperText="" label="Email" placeholder="Email Address" name="email" />
						</Grid>
						<Grid item xs="12">
							<InputField error={false} helperText="" label="Password" placeholder="Password" name="password" />
						</Grid>
						<Grid item xs="12">
							<p>
								Forgot password? <span className="colour-accent-2">Click here</span> credentials.
							</p>
						</Grid>
						<Grid item xs="12" sm="5">
							<Button disabled={false} fullWidth>
								Sign In
							</Button>
  </Grid> */}
					</Grid>
				</Card>
			</Grid>
		</Grid>
  );
}

export default LoginPage;