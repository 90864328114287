import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { MenuItem, Select as MuiSelect} from '@material-ui/core';

import Select from 'react-select'
import { debounce } from 'throttle-debounce';
import { withRemote } from '../../utils/withRemote';

const SittingOptions = ({sitting,day, court, judges, currentVals, renderJudge, setPrimaryJudge, setCase, setPanelSize, remote, setError}) => {


	const {title, primaryJudge, panelSize} = currentVals || {};

	console.log(`Sitting on day ${day} in court ${court} has title ${title}`, currentVals)

	const [controlledSize, setControlledSize] = useState();

	const [vals, setVals] = useState({
		title,
		primaryJudge,
		panelSize
	})

	const _setCase = (evt) => {
		//setCase(court, day, evt.target.value);
		// console.log("Setting case with evt ", evt);
		// console.log("Sending ",{court, day, evt})
		//delayedSetCase(court, day, evt.target.value);

		console.log("Updating sitting ",sitting)
		console.log("Updating for day/court",day,court)
		console.log(evt.target.value);

		vals.title = evt.target.value;
		setVals({...vals});
		remote.post(`/empanelment/sitting/${sitting.id}/${day}/${court}`,vals).catch(e=>setError("Error updating"));
	}
	const delayedSetCase = debounce(500,_setCase);

	const _setPanelSize = (evt) => {
		vals.panelSize = evt.target.value;
		setVals({...vals});
		remote.post(`/empanelment/sitting/${sitting.id}/${day}/${court}`,vals).catch(e=>setError("Error updating"));
	}

	const _setPrimaryJudge = (judge, action) => {
		console.log("Setting primary judge ",judge,action)
		vals.primaryJudge = judge;
		setVals({...vals});
		remote.post(`/empanelment/sitting/${sitting.id}/${day}/${court}`,vals).catch(e=>setError("Error updating"));
	}

	return <>
	<TextField
		key={`d${day}_c${court}_case`}
		size="small"
		padding="none"
		margin="dense"
		id="caseName"
		label="Case title or descriptor"
		type="text"
		fullWidth={true}
		defaultValue={title}
		onChange={delayedSetCase}/>
	
	<Select 
		placeholder={"Select primary judge"}
		isClearable={false} 
		options={judges} 
		getOptionLabel={renderJudge} 
		getOptionValue={(judge) => judge.id} 
		defaultValue={primaryJudge} 
		onChange={_setPrimaryJudge}
		menuPortalTarget={document.body} 
		
		styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
		/>

	<div>Panel size:&nbsp; &nbsp; &nbsp; 
	<MuiSelect labelId="panel-size" defaultValue={panelSize} onChange={_setPanelSize}>
		<MenuItem value="1" aria-label="1">1</MenuItem>
		<MenuItem value="3" aria-label="3">3</MenuItem>
		<MenuItem value="5" aria-label="5">5</MenuItem>
		<MenuItem value="7" aria-label="7">7</MenuItem>
	</MuiSelect></div>
	</>

}

export default withRemote(SittingOptions);