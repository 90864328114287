import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Paper, Select as MaterialSelect, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withRemote } from '../../../utils/withRemote';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select'

const SupremeCourtScheduleAdmin = (props)=> {

	const [courts, setCourts] = useState([]);
	const [judges, setJudges] = useState([]);
	const [schedule, setSchedule] = useState([]);

	const [editJudgesModal, setEditJudgesModal] = useState(null);
	const [editCallOverModal, setEditCallOverModal] = useState(null);

	const {remote} = props;
	
	useEffect(() => {
		props.remote.get("/admin/court-locations").then(setCourts);
		props.remote.get("/admin/sitting-judges").then(setJudges);
		props.remote.get("/admin/supreme-court-schedule").then(setSchedule);
	}, [])

	const useStyles = makeStyles({
		table: {
			minWidth: 650,
		},
		fancyButton: {
			background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
			border: 0,
			borderRadius: 3,
			boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
			color: 'white',
			height: 48,
			padding: '0 30px',
		},
		box: {
			padding: '10px 0'
		}
	});

	const shortenTitle = (judge) => {
		return judge.title.split(" ").map((n)=>n[0]).join('');
	}

	const renderJudge = (judge) => {
		return judge && judge.familyName+", " + shortenTitle(judge);
	}

	const renderList = (row) => {
		if (!row) return null;
		return <ul>{row.sittingJudges.map(r => <li key={r.id}>{renderJudge(r)}</li>)}</ul>
	}

	const editCallOver = (row) => {
		console.log("Editing callover for ", row);
	}

	const editList = (row) => {
		console.log("Editing list for ", row);
		//setEditJudgesModal(row);
	}

	const changeSittingJudge = (e, a, r) => {
		console.log("Changing sitting judge, ", e, a, r)
		if (a.action === 'remove-value') {
			const removed = a.removedValue;
			remote.delete(`/admin/supreme-court-schedule/${r.id}/judge/${removed.id}`)
		} else if (a.action === 'select-option') {
			const added = a.option;
			remote.put(`/admin/supreme-court-schedule/${r.id}/judge/${added.id}`)

		} else {
			console.warn("Unhandled change from changeSittingJudge", e);
		}
	}

	const classes = useStyles();

		return(
		<>

		<Dialog open={editJudgesModal} onClose={() => setEditJudgesModal(null)} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Edit Available Judges</DialogTitle>
				<DialogContent>
				<DialogContentText>
					Add new available Judge, or remove existing.
				</DialogContentText>
				{renderList(editJudgesModal)}
				<hr/>
				<Select options={judges} getOptionLabel={(judge) => judge.familyName}/>

				</DialogContent>
				<DialogActions>
				</DialogActions>
			</Dialog>


			<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
				<TableRow >
					<TableCell component="th" style={{ width: "15%" }}>From</TableCell>
					<TableCell component="th" style={{ width: "15%" }}>To</TableCell>
					<TableCell component="th" style={{ width: "15%" }}>Court</TableCell>
					<TableCell component="th" style={{ width: "15%" }}>Call Over</TableCell>
					<TableCell component="th" style={{ width: "40%" }}>Available for Supreme Court</TableCell>
				</TableRow>
				</TableHead>
				<TableBody>
				{schedule.map((row) => (
					<TableRow key={row.id}>
					<TableCell scope="row">
						{row.fromDate}
					</TableCell>
					<TableCell >
						{row.toDate}
					</TableCell>
					<TableCell >
						{row.court.name}
					</TableCell>
					<TableCell onClick={() => editCallOver(row)}>
						{row.callOverJudge?.familyName}
					</TableCell>
					<TableCell onClick={() => editList(row)}>
						<Select isMulti isClearable={false} options={judges} getOptionLabel={renderJudge} getOptionValue={(judge) => judge.id} defaultValue={row.sittingJudges} onChange={(e,a) => changeSittingJudge(e, a, row)}/>
					</TableCell>

					</TableRow>
				))}
				</TableBody>
			</Table>
			</TableContainer>
		</>
	)

}

export default withRemote(SupremeCourtScheduleAdmin);
