import { Accordion, AccordionDetails, AccordionSummary, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Table from '../../components/Table';
import ToolTipIcon from '../../icons/ToolTipIcon';
import { withRemote } from '../../utils/withRemote';
import Button from '../../components/Button';
import './LinkManagement.scss';


function LinkManagement(props) {

	const [links, setLinks] = useState([]);
	const [frontLinks, setFrontLinks] = useState([]);

	const [sectionEdit, setSectionEdit] = useState(null);
	const [linkEdit, setLinkEdit] = useState(null);
	const [linkCreate, setLinkCreate] = useState(null);

	const [editingObject, setEditingObject] = useState(null);

useEffect(() => {
	props.remote.get("/links")
		.then(setLinks)
		.catch(error => console.error(error));
	props.remote.get("/admin/front-links")
		.then(setFrontLinks)
		.catch(error => console.error(error));
},[props.remote]);

const deleteSection = (id) => {
	console.log("Confirm deleting section and all its bits?", id);

}

const editSection = (id) => {
	const link = links.filter(l => l.id === id)[0];
	setEditingObject({"title":link.linkHeader, "id":id});
	setSectionEdit(id);
}

const editLink = (id) => {
	const link = links.flatMap(x => x.links).filter(l => l.id === id)[0];
	setEditingObject({"id":id, "title":link.description, "link":link.link});
	setLinkEdit(id);
}

const deleteLink = (id) => {
	props.remote.delete("/links/"+id)
		.then(setLinks)
		.catch(error => console.error(error));
}

const createLink = (id) => {
	setEditingObject({"title":"", "link":""});
	setLinkCreate(id)
} 

const updateSectionTitle = () => {
	props.remote.put("/link-section", {id: editingObject.id, linkHeader: editingObject.title})
		.then(setLinks)
		.catch(error => console.error(error));
	handleClose();
}

const updateLink = () => {
	props.remote.put("/links/"+editingObject.id, {id: editingObject.id, description: editingObject.title, link: editingObject.link})
		.then(setLinks)
		.catch(error => console.error(error));
	handleClose();
}

const createLinkImpl = () => {
	props.remote.post("/links/"+linkCreate, {description: editingObject.title, link: editingObject.link})
		.then(setLinks)
		.catch(error => console.error(error));
	handleClose();
} 

const handleChange = (e, which) => {
	editingObject[which] = e.target.value;
	setEditingObject({...editingObject});

}

const handleClose = () => {
	setSectionEdit(null);
	setLinkEdit(null);
	setLinkCreate(null);
	setEditingObject(null);
}

return (
	<>
	<div className="links-section">


		{/*<p>{filter === 'favourites' ? "Show all" : "Show favourites only"}</p>*/}
		{links.map(linkSection => <>
			
			<div class="d-flex justify-content-start link-section-edit header">
				<ToolTipIcon icon="edit" title="Edit" classPrefix="edit" onClick={() => editSection(linkSection.id)}   />
				<ToolTipIcon icon="trash-2" title="Delete" classPrefix="delete" onClick={() => deleteSection(linkSection.id)}   />
				<ToolTipIcon icon="plus-circle" title="New Link" classPrefix="create" onClick={() => createLink(linkSection.id)}   />
			<h3 key={linkSection.id}>{linkSection.linkHeader} </h3>
				</div>
				{linkSection.links.map(link => <div class="d-flex justify-content-start link-section-edit">
					<ToolTipIcon icon="edit" title="Edit" classPrefix="edit" onClick={() => editLink(link.id)}   />
				<ToolTipIcon icon="trash-2" title="Delete" classPrefix="delete" onClick={() => deleteLink(link.id)}   />
				<p key={"link"+link.id}>{link.description}</p>
				</div>)}
			</>)}
	</div>
	<Dialog open={sectionEdit} onClose={handleClose} aria-labelledby="form-dialog-title">
		<DialogTitle id="form-dialog-title">Edit Link Section Title</DialogTitle>
		<DialogContent>
			<DialogContentText>
				This will update the title of the links section.  Users will need to refresh on their phones to see this update.
			</DialogContentText>
			<TextField
				autoFocus
				margin="dense"
				id="name"
				label="Title"
				type="text"
				fullWidth
				value={editingObject && editingObject.title}
				inputProps={{ maxLength: 90 }}
				onChange={(e) => handleChange(e, 'title')}
			/>
		</DialogContent>
		<DialogActions>
			<Button onClick={updateSectionTitle} disabled={editingObject && editingObject.title.trim() === ''} color="primary">
				Update
			</Button>
		</DialogActions>
	</Dialog>
	<Dialog open={linkEdit} onClose={handleClose} aria-labelledby="form-dialog-title">
		<DialogTitle id="form-dialog-title">Edit Link</DialogTitle>
		<DialogContent>
			<DialogContentText>
				This will update the details of the link.  Users will need to refresh on their phones to see this update.
			</DialogContentText>
			<TextField
				autoFocus
				margin="dense"
				id="name"
				label="Title"
				type="text"
				fullWidth
				value={editingObject && editingObject.title}
				inputProps={{ maxLength: 90 }}
				onChange={(e) => handleChange(e, 'title')}
			/>
			<TextField
				autoFocus
				margin="dense"
				id="name"
				label="Link"
				type="text"
				fullWidth
				value={editingObject && editingObject.link}
				inputProps={{ maxLength: 90 }}
				onChange={(e) => handleChange(e, 'link')}
			/>
		</DialogContent>
		<DialogActions>
			<Button onClick={updateLink} disabled={editingObject && editingObject.link && (editingObject.title.trim() === '' || editingObject.link.trim() === '')} color="primary">
				Update
			</Button>
		</DialogActions>
	</Dialog>
	<Dialog open={linkCreate} onClose={handleClose} aria-labelledby="form-dialog-title">
		<DialogTitle id="form-dialog-title">Edit Link</DialogTitle>
		<DialogContent>
			<DialogContentText>
				Create a new link under this section.  Users will need to refresh on their phones to see this update.
			</DialogContentText>
			<TextField
				autoFocus
				margin="dense"
				id="name"
				label="Title"
				type="text"
				fullWidth
				value={editingObject && editingObject.title}
				inputProps={{ maxLength: 90 }}
				onChange={(e) => handleChange(e, 'title')}
			/>
			<TextField
				autoFocus
				margin="dense"
				id="name"
				label="Link"
				type="text"
				fullWidth
				value={editingObject && editingObject.link}
				inputProps={{ maxLength: 90 }}
				onChange={(e) => handleChange(e, 'link')}
			/>
		</DialogContent>
		<DialogActions>
			<Button onClick={createLinkImpl} disabled={editingObject && editingObject.link && (editingObject.title.trim() === '' || editingObject.link.trim() === '')} color="primary">
				Update
			</Button>
		</DialogActions>
	</Dialog>
	</>
);

	}

	
export default withRemote(LinkManagement);