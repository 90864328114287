import React from 'react';
import DropDown from './DropDown';
import DropDownContent from './DropDownContent';
import DropDownToggler from './DropDownToggler';
import ProfilePicture from './ProfilePicture';
import "./ProfileDropdown.scss";
import Icon from '../icons/Icon';
import DropDownCard from './DropdownCard';
import DropDownItem from './DropdownItem';
import { Box } from '@material-ui/core';

function ProfileDropdown(props) {
	const {me, logout} = props;

	const logoutFunc = () => {
		console.log("Profile drop down logout");
		if (props.logout) {
			props.logout();
		} else {
			console.log("No logout from props!");
		}
	}
	return (
		<DropDown right>
			<DropDownToggler>
				<div className="profile-dropdown-toggle-items">
					<ProfilePicture user={me.displayName}/>
					<Box display={{ xs: 'none', md: 'block' }}>
						<div className="profile-name-wrapper">
							<div className="profile-name">{me.displayName}</div>
							<div className="profile-carat"><Icon icon="chevron-down" size="12" /></div>
						</div>
					</Box>
				</div>
			</DropDownToggler>
			<DropDownContent className="profile-menu">
				<DropDownCard>
					{/*<DropDownItem>Settings</DropDownItem>*/}
					<DropDownItem onClick={logoutFunc}>Logout</DropDownItem>
{/*					<DropDownItem activeItem>Test</DropDownItem>*/}
				</DropDownCard>
			</DropDownContent>
		</DropDown>
	);
}

export default ProfileDropdown;