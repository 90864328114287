import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AlertCardItem from '../components/AlertCardItem';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import isPast from 'date-fns/isPast'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import Schedule from './Schedule'
import SingleDaySchedule from './SingleDaySchedule'
import { withRemote } from '../utils/withRemote';
import formatISO from 'date-fns/formatISO'
import Icon from '../icons/Icon';
import { addDays } from 'date-fns';


function CalendarSection(props) {

	const [date, setDate] = useState(new Date());
	const [events, setEvents] = useState([]);

	const { remote } = props;

	useEffect(() => {
		remote.get("/events/"+formatISO(date, { representation: 'date' }))
			.then(setEvents)
			.catch(error => console.error(error));
	},[date]);

	const tileDisabled = ({ date, view }) => {
		// Disable tiles in month view only
		if (view === 'month') {
			return isPast(date);
		}
	}

	const prevDay = () => {
		setDate(addDays(date, -1))
	}

	const nextDay = () => {
		setDate(addDays(date, 1))
	}

	return (
		<div className="portal-schedule" style={{overflow:"auto"}}>
			<div class="headers">
				<div className="buttons">
					<div className="button-left button"><Icon
				icon="chevrons-left"
				size="30"
				className="navigate"
				onClick={prevDay}
			/></div>
					<div className="button-right  button"><Icon
				icon="chevrons-right"
				size="30"
				className="navigate"
				onClick={nextDay}
			/></div>
				</div>
				<div class="scroller syncscroll" name="myElements">

					<div class="track time">
						<div class="heading">Time</div>
					</div>
					<div class="track">
						<div class="heading">{format(date, "EEE do MMMM")}</div>
					</div>
					{/* <div class="track">
						<div class="heading">Tuesday</div>
					</div>
					<div class="track">
						<div class="heading">Wednesday</div>
					</div>
					<div class="track">
						<div class="heading">Thursday</div>
					</div>
					<div class="track">
						<div class="heading">Friday</div>
					</div> */}
				</div>
			</div>
			<div className="calendar-section">
				
					<SingleDaySchedule events={events}/>
				{/*<Grid container spacing={2}>
				<Grid item xs={12} lg={6}>
				<Calendar
					tileDisabled={tileDisabled}
				/>
				
				</Grid>
				<Grid item xs={12} lg={6}>
					<h6>This Week</h6>
					{events && events.map((evt,idx) => {
						return <AlertCardItem key={idx} text={evt.subject} date={format(parseISO(evt.start), 'do MMM yyyy HH:mm')} />
					})}
					
				</Grid>
				</Grid>*/}
			</div>
		</div>
	);
}

export default withRemote(CalendarSection);