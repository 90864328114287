import React from 'react';
import Card from './Card';
import "./DashboardCard.scss";

export default function DashboardCard(props) {

	const { children, className, header, headerContent, noHeader } = props;
	

	return (
		<Card className={`dashboard-card${className ? " " + className : ""}`}>
			{noHeader ? null : <div className="dashboard-card-header-wrapper">
				<h4>{header}</h4>
				{headerContent}
			</div>}
			<div className="dashboard-card-content">{children}</div>
		</Card>
	);
}