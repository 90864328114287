import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withRemote } from '../../../utils/withRemote';
import { makeStyles } from '@material-ui/core/styles';


const SittingJudgeAdmin = (props)=> {

	const [judges, setJudges] = useState([]);
	
	useEffect(() => {
		props.remote.get("/admin/sitting-judges").then(setJudges);
	}, [])

	const useStyles = makeStyles({
		table: {
			minWidth: 650,
		},
		fancyButton: {
			background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
			border: 0,
			borderRadius: 3,
			boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
			color: 'white',
			height: 48,
			padding: '0 30px',
		},
		box: {
			padding: '10px 0'
		}
	});


	const classes = useStyles();

		return(
		<>

			<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
				<TableRow >
					<TableCell component="th">Title</TableCell>
					<TableCell component="th">Prenominals</TableCell>
					<TableCell component="th">Given names</TableCell>
					<TableCell component="th">Family Name</TableCell>
					<TableCell component="th" >Postnominals</TableCell>
				</TableRow>
				</TableHead>
				<TableBody>
				{judges.map((row) => (
					<TableRow key={row.id}>
					<TableCell scope="row">
						{row.title}
					</TableCell>
					<TableCell >
						{row.prenominals}
					</TableCell>
					<TableCell >
						{row.givenNames}
					</TableCell>
					<TableCell >
						{row.familyName}
					</TableCell>
					<TableCell >
						{row.postnominals}
					</TableCell>
					</TableRow>
				))}
				</TableBody>
			</Table>
			</TableContainer>
		</>
	)

}

export default withRemote(SittingJudgeAdmin);
