import React, { useEffect, useState } from 'react';
import { withRemote } from '../utils/withRemote';

function StatisticsSection(props) {

	const [stats, setStats] = useState({
		top3Judges:[]
	});

	useEffect(() => {
		props.remote.get("/stats")
			.then(setStats)
			.catch(error => console.error(error));
	},[props.remote]);

	return (
		<div className="stats-section">
			<div class="mdc-card" style={{backgroundColor:"#F2F5FC", "padding":"5px", "margin":"5px"}}>
				<p>Your average cases per month</p>
				<h1>
					{stats && stats.averageCasesPerMonth}
				</h1>
			</div>
			<div class="mdc-card" style={{backgroundColor:"#F2F5FC", "padding":"5px", "margin":"5px"}}>
				<p>Outstanding cases</p>
				<h1>
					{stats && stats.outstandingCases}
				</h1>
			</div>
			<div class="mdc-card" style={{backgroundColor:"#F2F5FC", "padding":"5px", "margin":"5px"}}>
				<p>Top three judges</p>
				<ul>
					{stats && stats.top3Judges.map(j => <li>{j}</li>)}
				</ul>
			</div>
			
		</div>
	);
}

export default withRemote(StatisticsSection);