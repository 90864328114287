import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Paper, Select, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { withRemote } from '../../../utils/withRemote';

const CourtLocationAdmin = (props)=> {

	const [courts, setCourts] = useState([]);
	
	useEffect(() => {
		props.remote.get("/admin/court-locations").then(setCourts);
	}, [])

	const useStyles = makeStyles({
		table: {
			minWidth: 650,
		},
		fancyButton: {
			background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
			border: 0,
			borderRadius: 3,
			boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
			color: 'white',
			height: 48,
			padding: '0 30px',
		},
		box: {
			padding: '10px 0'
		}
	});


	const classes = useStyles();

		return(
		<>

			<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="simple table">
				<TableHead>
				<TableRow >
					<TableCell component="th">Name</TableCell>
				</TableRow>
				</TableHead>
				<TableBody>
				{courts.map((row) => (
					<TableRow key={row.id}>
					<TableCell scope="row">
						{row.name}
					</TableCell>

					</TableRow>
				))}
				</TableBody>
			</Table>
			</TableContainer>
		</>
	)

}

export default withRemote(CourtLocationAdmin);
