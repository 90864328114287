import React from 'react';

export default function DropDownToggler(props) {

	const { children, className } = props;
	
	return (
		<div className={`dropdown-toggler${className ? " " + className : ""}`}>
			{children}
		</div>
	);
}