import { Button, DialogActions, DialogContent, DialogContentText, IconButton, Snackbar, SnackbarContent, TextField} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withRemote } from '../../utils/withRemote';
import Icon from '../../icons/Icon';
import { makeStyles } from '@material-ui/core/styles';
import { DefaultSnackbar, ErrorSnackbar, MyRedSnackbar, SuccessSnackbar } from '../../components/SnackBars';
import Alert from '@material-ui/lab/Alert';

/* function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
} */

function Notification(props) {


	const [title, setTitle] = React.useState("");
	const [notification, setNotification] = React.useState("");

	const [success, setSuccess] = React.useState(false);
	const [error, setError] = React.useState(false);

	const createNotification = (e) => {
		const newNotification = {
			title,
			notification
		};
		props.remote.post("/notification",newNotification).then(() => {
			setTitle("");
			setNotification("");
			setSuccess(true);
		}).catch((e) => {
			setError(true);
		});
	}

	const handleClose = (event, reason) => {
		console.log("handling close ", event, reason);
		if (reason === 'clickaway') {
		  return;
		}
		console.log("setting success  ", success);
		setSuccess(false);
		setError(false);
		console.log("setting success  ", success);
	};

	
	const handleTitleChange = (event) => {
		setTitle(event.target.value);
	};
	const handleNotificationChange = (event) => {
		setNotification(event.target.value);
	};

	return (
		<>

			<DefaultSnackbar
				type={'success'}
				open={success}
				onClose={handleClose}
				message="Notification sent"
			/>
			<DefaultSnackbar
				type={'error'}
				open={error}
				onClose={handleClose}
				message="There was a problem sending the notification. Please contact support"
			/>
{/* 
			<Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
					There was a problem sending the notification, please contact support
				</Alert>
			</Snackbar> */}
			<DialogContent>
				<DialogContentText>
					<Alert severity="warning">Notifications server under maintenance!  You can still create notifications which will appear on Judge's phones, but they will currently not receive a push notification alert</Alert>
					Notifications will appear on the dashboard for all users.  Notifications are also pushed to all users of the app on their phones.
				</DialogContentText>
				<TextField
					autoFocus
					margin="dense"
					id="name"
					label="Title"
					type="text"
					fullWidth
					value={title}
					inputProps={{ maxLength: 90 }}
					onChange={handleTitleChange}
				/>
				<TextField
					margin="dense"
					id="title"
					label="Notification"
					type="text"
					multiline="true"
					fullWidth
					rowsMax={3}
					value={notification}
					inputProps={{ maxLength: 350 }}
					onChange={handleNotificationChange}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={createNotification} disabled={notification.trim() === '' || title.trim() === ''} color="primary">
					Create
				</Button>
			</DialogActions>
		</>
	)

}

export default withRemote(Notification);
