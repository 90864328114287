import React from 'react';
import "./AlertCardItem.scss";

export default function AlertCardItem(props) {

	const { text, subText, date, className, unread, noMargin} = props;
	
	if (props.item && props.item.link) {
		return <a href={props.item.link}>
			<div className={`alert-card-item${className ? " " + className : ""}${unread ? " unread-item" : ""}${noMargin ? " no-margin" : ""}`}>
				<div className="alert-card-item-text">{text}</div>
				{subText && <div className="alert-card-item-sub-text">{subText}</div>}
				<div className="alert-card-item-date">{date}</div>
			</div>

		</a>
	}

	return (
		<div className={`alert-card-item${className ? " " + className : ""}${unread ? " unread-item" : ""}${noMargin ? " no-margin" : ""}`}>
			<div className="alert-card-item-text">{text}</div>
			{subText && <div className="alert-card-item-sub-text">{subText}</div>}
			<div className="alert-card-item-date">{date}</div>
		</div>
	);
}