import { Button as MaterialButton, Fab, FormControl, FormHelperText, Grid, Tooltip, Input, InputLabel, TextField, Paper } from '@material-ui/core';

import Button from '../components/Button';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '../icons/Icon';
import { format, parse, parseJSON } from 'date-fns';

const useStyles = makeStyles((theme) => ({
	fab: {
		margin: theme.spacing(2),
	},
	absolute: {
		position: 'absolute',
		top: theme.spacing(2),
		right: theme.spacing(3),
	},
	paper: {
		padding: theme.spacing(5),
		position: 'relative',
		maxWidth: '700px',
		marginBottom: '25px'
	}
}));

const formatDate = (date) => {
	return format(parse(date, "yyyy-MM-dd'T'HH:mm", new Date()), 'dd/MM/yyyy HH:mmaaa')
}
	

function Training(props) {
	const classes = useStyles();
	const {training} = props;

	console.log(training);
	return (
		<>
				<h5 className="my-0">Training</h5>
			{/*
			<Grid item xs={3}>
				<Button variant="contained" color="primary" startIcon={<Icon icon={"plus-circle"} size="20" />} onClick={setCreateEvent} >Add Event</Button>
			</Grid>
			{createEvent && <>
				<Grid item xs={12}>
					<FormControl>
						<InputLabel htmlFor="title">Title</InputLabel>
						<Input errorText={errorText.title} id="title" aria-describedby="title-helper-text" value={event.title} onChange={(e)=>updateEvent(e,'title')}/>
						<FormHelperText id="title-helper-text">Enter the name of the training event.</FormHelperText>
					</FormControl>
				</Grid>
				<Grid item xs={4}>
					<FormControl>
						<InputLabel htmlFor="link">Link</InputLabel>
						<Input errorText={errorText.link} id="link" aria-describedby="link-helper-text" value={event.link} onChange={(e)=>updateEvent(e,'link')}/>
						<FormHelperText id="link-helper-text">Enter the link to more information, or to a zoom event etc.</FormHelperText>
					</FormControl>
				</Grid>
				<Grid item xs={4}>
					<TextField  errorText={errorText.date} id="date" label="Date" type="date" InputLabelProps={{ shrink: true, }} value={event.date} onChange={(e)=>updateEvent(e,'date')}/>
				</Grid>
				<Grid item xs={12}>
					<TextField  errorText={errorText.description}  margin="dense" id="description" label="Description" type="text" multiline="true" fullWidth rowsMax={3} value={event.description} onChange={(e)=>updateEvent(e,'description')}/>
				</Grid>
				<Button variant="contained" color="primary" size="20" onClick={createEventImpl} >Create Event</Button>
				<Button variant="contained" size="20" onClick={() => setCreateEvent(false)} >Cancel</Button>
			</>} */}
			{training && training.map(t => <Paper key={'training'+t.id} elevation={3} className={classes.paper}>
				
				<h3>{t.title}</h3>
				{t.image && <img src={`data:image/png;base64,${t.image}`} style={{width:"600px"}}/>}
				{t.eventTime && <p style={{paddingTop: "12px", paddingBottom: "12px"}}><b>Event time: </b> {formatDate(t.eventTime)}</p>}

				<p>{t.abstract}</p>
				{t.joinLink && <Tooltip title="Register" aria-label="Register"><a href={`https://${t.joinLink}`}><Button  style={{marginRight:"50px", padding:"8px 10px 8px 10px"}}>
Register
				</Button></a></Tooltip>}
				{t.zoomLink && <Tooltip title="Join on zoom" aria-label="Join on zoom"><a href={`https://${t.zoomLink}`}><Button style={{marginRight:"50px", padding:"8px 10px 8px 10px"}}>
Join on Zoom
				</Button></a></Tooltip>}
				
			</Paper>)}
			{/*<Grid item xs={12} lg={12}>
				<img src={glacy} /><Tooltip title="Join on zoom - this event has already been" aria-label="This event has already been">
						<Fab color="primary" className={classes.fab}>
							<Icon
								icon={"camera"}
								size="20"
							/>
						</Fab>
					</Tooltip>
				<p>Activity 3.2.5: Introductory Training Course on Cybercrime and Electronic Evidence
					for Judges (on-line)
				</p>
				<p>Societies worldwide are increasingly reliant on information technologies, thus also becoming more exposed and vulnerable to cybercrime and cyber-enabled crimes. Targeting and illegal exploitation of computer systems have become common, and international crises such as the COVID-19 pandemic seem to provide ever new ways for cyber-criminals to conduct their illicit activities. In light of these challenges, governments and in particular criminal justice authorities need to strengthen their ability to investigate, prosecute and cooperate internationally on cybercrime, so as to ensure that the rights of individuals and societies in cyberspace are protected.</p>
				<Grid item xs={12} lg={5}>
					<a href={glacyDoc}>Download <Icon
						icon={"book"}
						size="20"
					/></a>
				</Grid>
				

			</Grid>*/}

			{/* 			<Grid item xs={12} lg={5}>
				<InputField label="First Name" placeholder="First Name" name="first-name" />
			</Grid>
			<Grid item xs={12} lg={5}>
				<InputField label="Last Name" placeholder="Last Name" name="last-name" />
			</Grid>
			<Grid item xs={12} lg={5}>
				<InputField label="Email Address" placeholder="Email Address" name="email-address" />
			</Grid>
			<Grid item xs={12} lg={5}>
				<InputField label="Phone Number" placeholder="Phone Number" name="phone-number" />
			</Grid>
*/}		</>
	);
}

export default Training;