import React from 'react';
import "./LaunchIcon.scss";
import ToolTipIcon from "./ToolTipIcon";

export default function LaunchIcon(props) {	
	
	const openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}
	return (
		<ToolTipIcon icon="external-link" title="Open in new tab" classPrefix="launch" onClick={() => console.log('oncick from launch icon',props)}/>
	);
}