import { Redirect, useLocation } from "react-router-dom";
import queryString from "query-string";
import { useEffect } from 'react';
import { withRemote } from "./utils/withRemote";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
/**
 * Temp page for ws fed consent
 * @returns 
 */
function WsFed(props) {

//	const [code, setCode] = useState(null);

	let {search} = useLocation();
	const query = queryString.parse(search);
	console.log(query);
	const {setAuth} = props; 
	const remote = props.remote;


	useEffect(() => {
		console.log("WsFed use effect is firing");
		remote.post("/token", query)
		.then(res => {
			console.log("Got a response, ", res)
			setAuth(res);
		})
		.catch(error => {
			console.log("Error in the fetch", error);
			setAuth("temp auth after error");	
		});
		
	},[remote, query, setAuth]);

	if (!query.code) {
		return <Redirect to="/login" />
	}

	return (<div className="centered"><Loader
        type="Puff"
        color="#254A8F"
        height={100}
        width={100}
        timeout={3000} //3 secs
      /></div>);
}

export default withRemote(WsFed);