import { Button as MuiButton } from '@material-ui/core';
import "./Button.scss";

function Button(props) {

	const { variant, buttonColour, children, bgColour, onClick, className, disabled, fullWidth } = props;

	return (
		<MuiButton
			{...props}
			onClick={onClick}
			className={`p-button${className ? " " + className : ""}${buttonColour ? " colour-" + buttonColour : ""}${bgColour ? " bg-colour-" + bgColour : ""}${fullWidth ? " full-width" : ""}`}
			variant={variant && variant}
			disabled={disabled}
		>
			{children}
		</MuiButton>
	);
}

export default Button;