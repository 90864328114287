import { getHours, parseISO } from 'date-fns';
import './Schedule.scss';

export default function Schedule(props) {

	const { events } = props;

	const renderEvent = (event) => {
		const start = parseISO(event.start);
		const end = parseISO(event.end);
		const startHours = getHours(start) - 9;
		const endHours = getHours(end) - 9;
		const top = (startHours * 5) + "em";
		const height = (endHours - startHours) * 5 + "em";
		const text = event.body.replace(/\\r\\n/g,"\r\n");
		console.log(text);


		return (
			<div key={event.subject} style={{ position: "sticky", top: `${top}`, height: `${height}` }}>
				<div class="details">
					<h3>{event.subject}</h3>
					<p className="bodyPreview" style={{whiteSpace:'pre-line'}}>{text}</p>
				</div>
			</div>
		)

	}
	return (
		<div class="table">

			<div class="tracks syncscroll" name="myElements">
				<div class="track time">
					<div class="entry">
						<time>09.00</time>
					</div>
					<div class="entry">
						<time>10.00</time>
					</div>
					<div class="entry">
						<time>11.00</time>
					</div>
					<div class="entry">
						<time>12.00</time>
					</div>
					<div class="entry">
						<time>13.00</time>
					</div>
					<div class="entry">
						<time>14.00</time>
					</div>
					<div class="entry">
						<time>15.00</time>
					</div>
					<div class="entry">
						<time>16.00</time>
					</div>
					<div class="entry">
						<time>17.00</time>
					</div>
				</div>
				<div class="track blue">
					{events && events.map(renderEvent)}



				</div>



			</div>
		</div>)
}