import React from 'react';
import "./SearchField.scss";
import InputField from './InputField';
import Icon from '../icons/Icon';

function SearchField(props) {
	return (
		<div className="search-field-wrapper">
			<InputField className="search-field" placeholder="Search" name="search" />
			<Icon icon="search" size="15" />
		</div>
	);
}

export default SearchField;