import "./App.scss";
import PageLayout from "./layout/PageLayout";
import LoginPage from "./pages/LoginPage";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useState } from "react";
import WsFed from "./WsFed";
import { withRemote } from "./utils/withRemote";
import {ReactComponent as HomeIcon} from "./icons/home.svg";
import {ReactComponent as AdminIcon} from "./icons/admin.svg";
import {ReactComponent as TrainingIcon} from "./icons/training.svg";
import {ReactComponent as NewsIcon} from "./icons/news.svg";
import {ReactComponent as SettingsIcon} from "./icons/settings.svg";
// import LoginPage from "./pages/LoginPage";



function App(props) {

	const [auth,setAuth] = useState(sessionStorage.getItem("pngportal.auth.token"));


	const updateAuthStatus = (auth) => {
		console.log("Updating auth status with ", auth);
		sessionStorage.setItem("pngportal.auth.token", auth.token);
		setAuth(auth.token);
	}

	console.log("In app, auth state is currently ", auth);

	props.remote.registerLogoutHandler(() => {
		console.log("logout handler firing");
		sessionStorage.removeItem("pngportal.auth.token");
		setAuth(null);
	})

	const logout = () => {
		sessionStorage.removeItem("pngportal.auth.token");
		setAuth(null);
	}

	return (
		<>
			<Router>
				<Switch>
				<Route path="/login">
					{auth ? <Redirect to="/ui" /> : <LoginPage/>}
				</Route>
				<Route path="/ws-fed">
					{auth ? <Redirect to="/ui" /> : <WsFed setAuth={updateAuthStatus}/>}
				</Route>
				<PrivateRoute authed={auth} logout={logout} path='/ui' component={PageLayout}/>
				<Route exact path="/" render={() => {
                    return (
                      auth ?
                      <Redirect to="/ui" /> :
                      <Redirect to="/login" /> 
                    )
                }}
              />
				</Switch>
			</Router>
		</>
	);
}

function PrivateRoute ({component: Component, authed, logout, ...rest}) {
	console.log("Private Route rest: ", rest);
	return (
	  <Route
		{...rest}
		render={(props) => authed 
		  ? <Component {...props} logout={logout} />
		  : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
	  />
	)
  }

export default withRemote(App);
