class RemoteImpl {
    constructor() {
		console.log("Remote Impl constructor");
        this.base = process.env.REACT_APP_REMOTE_BASE;
		this.tokenListeners = [];
    }

	logout() {
        window.sessionStorage.removeItem("pngportal.auth.token");
        if (this.registeredLogoutAction)
            this.registeredLogoutAction();
	}

    registerLogoutHandler(logout) {
        this.registeredLogoutAction = logout;
    }

	registerTokenListener(listener) {
		this.tokenListeners.push(listener);
	}

	unregisterTokenListener(listener) {
		const index = this.tokenListeners.indexOf(listener);
		if (index > -1) 
			this.tokenListeners.splice(index, 1);
	}

    handleSessionTimeout() {
        this.logout();
    }

    createTokenHeader() {
        let token = window.sessionStorage.getItem("pngportal.auth.token");
        if (!token) {
            return {
                headers: {}
            };
        }
        return {
            headers: {
                "X-PNG-Session-Token": token
            }
        };
    }

    setToken(resp) {
        let newToken = resp.headers.get("X-PNG-Set-Token");
		if (newToken) {
			window.sessionStorage.setItem("pngportal.auth.token", newToken);
			this.tokenListeners.forEach(listener=>listener(newToken));
		}
    }

    handleResponse(resp, options) {
        if (resp.ok) {
            this.setToken(resp);
            if (options.rawResponse)
                return resp;
            else 
                return resp.json();
        } else {
            if (resp.status === 418 || resp.status === 401) {
                this.handleSessionTimeout();
            } else {
                //Update the user token regardless
                this.setToken(resp);
            }
            return Promise.reject(resp);
        }
    }
    get(apiURL, options) {
        let headers = this.createTokenHeader();
        options = options || {};
        var url = this.base + apiURL;
        if (options.timeout !== null) {
           return fetch(url, headers)
                .then((resp)=>{
                    return this.handleResponse(resp, options);
                });
            //TODO handle unauthorised
        } else {
            return this.timeout(20000, fetch(url, headers))
                .then((resp)=>{
                    return this.handleResponse(resp, options);
                });
                // .then((data)=>{
                //         handler(data);
                // }).catch(e=>{
                //         if (timeout) timeout(e);
                // });
        }
    }

    put(apiURL, content, options) {
        return this.send("put", apiURL, content, options);
    }

    post(apiURL, content, options) {
        return this.send("post", apiURL, content, options);
    }

    delete(apiURL, content, options) {
        return this.send("delete", apiURL, content, options);
    }
    
    send(method, apiURL, content, options) {
        let headers = this.createTokenHeader();
        options = options || {};
        var url = this.base + apiURL;

        headers.headers["Content-Type"] = "application/json; charset=utf-8";
        Object.assign(headers, {
            method: method,
            body: JSON.stringify(content),
            credentials: "omit"
        });

        if (options.timeout !== null) {
            return fetch(url, headers)
            .then((resp)=>{
                return this.handleResponse(resp, options);
	        }).catch((e) => {
				console.log("send caught ",e)
				return Promise.reject(e);
			});
        } else {
            return this.timeout(20000, fetch(url, headers))
                .then((resp)=>{
                    return this.handleResponse(resp, options);
                });
                // .then((data)=>{
                //         handler(data);
                // }).catch(e=>{
                //         if (timeout) timeout(e);
                // });
        }
    }

    timeout(millis, promise) {
        return new Promise(function(resolve, reject) {
            setTimeout(function() {
                reject(new Error("timeout"))
            }, millis);
            promise.then(resolve, reject)
        })
    }
}

export default RemoteImpl;
