import { Box, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import SearchField from '../components/SearchField';
import NotificationBell from '../components/NotificationBell';
import { useLocation } from "react-router-dom";
import ProfileDropdown from '../components/ProfileDropdown';
import "./PageContentHeader.scss";
import VerticalDivider from '../components/VerticalDivider';


function PageContentHeader(props) {
	const { pages, me, notifications, setNotifications } = props;
	const [page,setPage] = useState(pages[0]);

	let location = useLocation();

	React.useEffect(() => {
		setPage(pages.find(p=>p.path === location.pathname));
	}, [location, pages]);

	return (
		<div className="page-content-header-wrapper">
			<div className="page-content-header-inner">
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<span className="header-icon-wrapper">
							{page && page.icon}
							<h1 className="page-title">{page && page.label}</h1>
						</span>
					</Grid>
					<Grid item xs={1} lg={4}>
						<Box display={{ xs: 'none', lg: 'block' }}>
							{/*<SearchField />*/}
						</Box>
					</Grid>
					<Grid container  xs={4}  justify="flex-end" alignItems="center">
						{<Grid item >
							<NotificationBell notifications={notifications} setNotifications={setNotifications} />
	</Grid>}
						<Grid item >
							<VerticalDivider height="30" margin="30"/>
						</Grid>
						<Grid item >
							<ProfileDropdown logout={props.logout} me={me}/>
						</Grid>
					</Grid>
				</Grid>
			</div>
		</div>
	);
}


export default PageContentHeader;