import React from 'react';
import Icon from '../icons/Icon';
import "./NotificationBell.scss";
import NotificationList from './NotificationList';

const dummyNotifications = [
	{ text: "lorem ipsum something updated", unread: true, date: "1 day ago" },
	{ text: "lorem ipsum something updated lorem dolor", unread: false, date: "3 days ago" },
	{ text: "lorem ipsum something updated lorem dolor", unread: false, date: "3 days ago" },
	{ text: "lorem ipsum something updated lorem dolor", unread: false, date: "3 days ago" },
];
	
function NotificationBell(props) {
	const [open, setOpen] = React.useState(false);
	const { notifications, setNotifications } = props;
  
	const recentNotifications = notifications.slice(0,5);
	const unreadNotifications = recentNotifications.some(x => x.read === false);
	const handleToggle = () => {
	  setOpen((prevOpen) => !prevOpen);
	};
	
	return (
		<div className={`notification-bell-wrapper${unreadNotifications ? " unread-notifications" : ""}${open ? " notifications-open" : ""}`}>
			<Icon
				icon="bell"
				size="30"
				className="notification-bell"
				onClick={handleToggle}
			/>
			<div className="notifications-wrapper">
				<NotificationList notifications={recentNotifications} setNotifications={setNotifications} />
			</div>
		</div>
	);
}

export default NotificationBell;