import React, { useEffect, useState } from 'react';
import AlertCardItem from '../components/AlertCardItem';
import { withRemote } from '../utils/withRemote';

import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import Loader from 'react-loader-spinner';

function NewsSection(props) {

	const [news, setNews] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {

		props.remote.get("/news")
			.then((news)=>{
				setNews(news);
				setLoading(false);
			})
			.catch(error => {console.error(error);setLoading(false);});
	},[]);

	const decodeHtml = (html) => {
		var txt = document.createElement("textarea");
		txt.innerHTML = html;
		return txt.value;
	}

	if (loading) {
		return <Loader
        type="ThreeDots"
        color="#254A8F"
        height={30}
        width={100}
      />
	}

	return (
		<div>
			<p>News headlines from <a href="https://emtv.com.pg">EMTV</a></p>
			{news.map((item,idx)=>{
				const decodedText = decodeHtml(item.synopsis);
				return <AlertCardItem text={item.headline} subText={decodedText} item={item} date={format(parseISO(item.date), 'do MMM yyyy HH:mm')}/>
			})}
			
		</div>
	)
}

export default withRemote(NewsSection);