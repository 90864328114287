import React from 'react';
import "./DropdownCard.scss";

export default function DropDownCard(props) {

	const { children, className } = props;
	
	return (
		<div className={`dropdown-card${className ? " " + className : ""}`}>
			{children}
		</div>
	);
}