import React from 'react';
import { NavLink } from 'react-router-dom';
import "./NavLinks.scss";

function NavLinks(props) {
	const { pages } = props;
	return (
		<div className="nav-links-wrapper">
			{pages &&
				pages.length >= 0 &&
				pages.map((page, idx) => (
					<NavLink className="nav-link-item" activeClassName="active-nav-link-item" key={idx} to={page.path} exact={page.exact}>
						{page.icon}{page.label}
					</NavLink>
				))}
		</div>
	);
}

export default NavLinks

